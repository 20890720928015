<template>
    <div>
        <section>
            <div class="container">
                <div class="article-container">
                    <div class="article-wrapper">
                        <h2 class="title-font">
                            <span class="text-blue">Оставить заявку</span>
                            <br>
                            на бесплатную консультацию
                        </h2>
                        <br>
                        <p class="description-font">Заполните форму ниже, и наша команда специалистов свяжется с вами в ближайшее время.</p>
                    </div>
                </div>
                <div class="article-container">
                    <div class="petition-wrapper">
                        <div class="petition-content ">
                            <span class="description-font">Имя
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <input v-model="name" type="text" class="petition-select description-font">
                        </div>
                        <div class="petition-content">
                            <span class="description-font">Номер телефона</span>
                            <input v-model="phoneNumber" class="petition-select description-font"
                                @change="maskPhoneNumber"  type="tel">
                        </div>
                        <div class="petition-content">
                            <span class="description-font">номер STIR</span>
                            <input v-model="STIR" class="petition-select description-font"
                                @change="maskSTIR">
                        </div>
                        <div class="article-questions-btn-container">
                            <button class="noactive-btn  frameBtn w-auto" @click="sendMessage()">Отправить</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>

export default {
    name: 'petition',
    data() {
        return {
            name: '',
            phoneNumber: '+998',
            STIR: ''
        }
    },
    methods: {
        maskSTIR(event) {
           this.STIR = event.target.value.replace(/\D/g, '');
        },
        maskPhoneNumber(event) {
            const phoneInput = event.target;
            if (phoneInput.value.length > 4) {

                if (phoneInput.value.startsWith('+998')) {

                    let phoneNumber = phoneInput.value.replace(/\D/g, '');
                    if (phoneNumber.length > 12) {
                        phoneNumber = phoneNumber.substring(0, 12);
                    }
                    this.phoneNumber = '+' + phoneNumber
                }
                else {
                this.phoneNumber = '+998'
                }
            }
            else {
                this.phoneNumber = '+998'

            }

        },
        validation() {
            if (this.phoneNumber.length != 13) {
                return 'Введите правильный номер телефона'
            }
            if (this.name.length < 2) {
                return 'Введите ваше имя'
            }
            return true
        },
        sendMessage() {
            if (this.validation() === true) {

                var telegram_bot_id = "6216090042:AAFPUDOtJyDzZdshUEiBH0j0tupGI6aXA-A";
                var chat_id = -1001899599724;
                var name = this.name;
                var phoneNumber = this.phoneNumber;
                var Stir = this.STIR;
                var content ="Faktura.Report"+"\n\nIsmi: " + name + "\nTelefon Raqam: " + phoneNumber + "\nSTIR raqam: " + Stir ;

                var url = "https://api.telegram.org/bot" + telegram_bot_id + "/sendMessage"
                var headers = {
                    "Content-Type": "application/json",
                    "cache-control": "no-cache"
                }
                var data = JSON.stringify({
                    "chat_id": chat_id,
                    "text": content
                })
                fetch(url, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: headers,
                    body: data
                }).then(response => {
                    if (response.status == 200) {
                        this.$router.push("thanks")
                    }
                })
            }
            else {
                alert(this.validation());
            }
        }

    },
  metaInfo() {
    return {
      title: "Оставить заявку на бесплатную консультацию",
      meta: [
        {
          name: "description",
          content:
            "Заполните форму ниже, и наша команда специалистов свяжется с вами в ближайшее время.",
        },
      ],
    };
  }
}
</script>

<style scoped>
.article-wrapper {
    margin-top: 20px;
    max-width: 625px;
    text-align: center;
}

.article-title-description {

    padding: 5px;
    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: initial;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    text-align: center;
    color: #676767;
}

.petition-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 55%;
}

.petition-content {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.petition-content-text {

    font-family: Lab Grotesque,-apple-system,BlinkMacSystemFont,Arial,Liberation Sans,Nimbus Sans L,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #676767;

}

.petition-select {
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    height: 38px;
    border: 1px solid #BCBCBC;
    border-radius: 5px;
    width: 70%;
}

.article-questions-btn-container {
    margin-top: 45px;
}

@media (max-width: 767px) {
    .petition-select {
        width: 95%;
    }
    .article-wrapper {
        width: 85%;
    }
    .petition-content {
        padding-left: 25px;
        padding-right: 15px;
        width: 100%;
    }

    .petition-wrapper {
        width: 100%;
    }
}
</style>