<template>
  <div id="Landing-page">
    <mainheader></mainheader>
    <section>
      <div class="container">
        <div class="wrapper">

          <div id="aboutService">
            <div class="row justify-content-between">
              <div class="col d-flex flex-column  justify-content-center text-left">
                <h2 class=" text-left title-font"><span class="text-blue ">Генерация готовых отчетов</span> <br>
                  за указанный период</h2>
                <p class="description-font">Автоматическая генерация отчетов по входящим и исходящим документам с
                  возможностью создания месячных, квартальных и годовых отчетов</p>
                <div>
                  <button @click="redirectUrl('application')" class="noactive-btn  frameBtn w-auto"> Бесплатная
                    консультация</button>
                </div>
              </div>
              <div class="col landing-img-container justify-content-end">
                <img src="../assets/images/rasilka-image.png" width="360">
              </div>
            </div>
            <div id="rasilka" class="mt-5">
              <div class="box-possibilities height-auto pad50-20 shadow-rgba300 shadow-none-991">
                <div class="row">
                  <div class="col d-flex justify-content-center ">
                    <img src="../assets/images/rasilka-img-filter.png" style="max-width: 280px; min-width: 100px;">
                  </div>
                  <div class="col text-left">
                    <h2 class=" text-left pt-3 title-font "><span class="text-blue">Гибкая настройка</span> отчетов </h2>
                    <p class="description-font ">Укажите ИНН, выберите тип и период отчета, добавьте дополнительные
                      параметры. После генерации получите точный и структурированный отчет в один клик.</p>
                    <p class="description-font font-weight-bold">
                      Параметры для генерации отчетов:
                    </p>
                    <div class="d-flex justify-content-start flex-wrap">
                      <div style="margin-right: 20px;">
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> ИНН Контрагента
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> Типу отчета
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По дате ЭСФ
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По периоду
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По филиалу
                        </p>
                      </div>
                      <div>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> Типу отправки

                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По статусу
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По номеру договора
                        </p>
                        <p class="description-font ">
                          <svg width="25" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 011-1h10a1 1 0 110 2H3a1 1 0 01-1-1z" fill="#4E7BF9" />
                          </svg> По шаблону
                        </p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="visualnayaChat">
            <possibilityComponent class="pb-5" v-for="(possibility, i) in possibilities" :id="i + '-possibility'"
              :possibility="possibility" :index="i" :key="i">
            </possibilityComponent>
          </div>
          <div id="audience" class="mt-1">
            <div class="box-possibilities height-auto pad50-20 shadow-rgba300 shadow-none-991 mt-2">
              <div class="row">
                <div class="col text-left">
                  <h2 class="text-left pt-3 title-font"><span class="text-blue">Индивидуальный</span> шаблон</h2>
                  <p class="description-font">Независимо от типа отчетности или специфики вашего бизнеса, наши специалисты
                    помогут разработать персонализированный шаблон, позволяющий генерировать отчеты точно в соответствии с
                    вашими требованиями и предпочтениями.</p>
                  <div>
                    <button @click="redirectUrl('application')" class="noactive-btn  frameBtn w-auto">Заказать
                      индивидуальный шаблон</button>
                  </div>
                </div>
                <div class="col landing-img-container">
                  <img src="../assets/images/InduvidualTemplate.png" style="max-width: 300px; min-width: 100px;">
                </div>
              </div>
            </div>
          </div>
          <div id="price" class="mt-5 row justify-content-between">
            <div class="col d-flex justify-content-center align-items-center">
              <img src="../assets/images/freeVersion.png" class=""
                style="max-width: 400px; min-width: 100px; max-height: 400px;">
            </div>
            <div class="col d-flex flex-column  justify-content-center text-left">
              <h2 class="font-weight-bold text-left title-font"><span class="text-blue">Бесплатная версия </span> <br>
                генерации отчетов</h2>
              <p class="description-font">Пользуйтесь бесплатной версией нашего сервиса и генерируйте специальные отчеты
                за предыдущие <span class="font-weight-bold">
                  &nbsp;&nbsp;&nbsp; 30 дней абсолютно бесплатно.
                </span>
              </p>
              <p class="description-font">
                Воспользуйтесь стандартным шаблоном для получения ключевой
                информации о вашем бизнесе.
              </p>
              <p class="description-font">
                <span class="font-weight-bold">Ограничено тремя запросами в месяц</span>, эта версия предоставляет быстрый и удобный
                способ получения важных данных без затрат.
              </p>

              <!-- <div class="d-flex">
                <p class="text-dark mx-1"> ✽</p>
                <p class="description-font"><span class="text-dark font-weight-bold">Клики:</span> количество
                  пользователей, которые открыли вашу рекламную
                  рассылку.</p>
              </div> -->
            </div>
          </div>
          <petitionComponent id="application"></petitionComponent>
        </div>

      </div>
    </section>

    <mainfooter></mainfooter>
  </div>
</template>
  
<script>
import mainheader from "../components/mainheader.vue";
import mainfooter from "../components/mainfooter.vue";
import possibilityComponent from '../components/possibilityComponent.vue'
import petitionComponent from '../components/Petition.vue'
export default {
  name: "Landing-page",

  components: {
    mainheader,
    mainfooter,
    possibilityComponent,
    petitionComponent
  },
  methods: {
    redirectUrl(hashtag) {
      if (this.$route.path != '/') {
        this.$router.push({ name: 'home' });
      }
      window.scrollTo(0, 0);
      let position = document.getElementById(hashtag).getBoundingClientRect()
      window.scrollTo({
        top: position.y - 80,
        behavior: 'smooth'
      })
    },

  },
  data() {
    return {
      screenWith: window.innerWidth,
      spinerInProgers: false,
      possibilities: [
        {
          title: 'Процесс создания',
          description: '',
          tabs: [
            {
              width: '90%',
              btn: 'Параметры отчёта',
              img: 'InvoiceSettings.png',
              content: 'Настройте отчет по своим потребностям с помощью нашего удобного интерфейса. Гибкие опции настроек позволяют вам получить именно тот отчет, который вам необходим'
            },
            {
              width: '90%',
              btn: 'Готовый отчёт',
              img: 'InvoiceDone.png',
              content: 'На скриншоте представлен готовый отчет, созданный по заданным параметрам, доступным для настройки через нашу систему, где каждая строка представляет отдельный документ'
            }
          ]
        }
      ]
    };
  },
  metaInfo() {
    return {
      title: "Электронный документооборот в Узбекистане - внедрение ЭДО",
      meta: [
        {
          name: "description",
          content:
            " Ведение электронного документооборота(ЭДО) в Узбекистане.⏩ Faktura.EDO - программа электронного документооборота онлайн. ⏩ Адекватная цена. ☎️: +998 (71) 200-00-13.",
        },
        {
          name: "keywords",
          content:
            "faktura edo, faktura, faktura uz, soliq faktura, faktura soliq uz, e faktura, e faktura uz, soliq servis uz faktura, soliq servis faktura, faktura ru, e faktura soliq uz, e faktura soliq, soliq servis uz faktura ru, uzasbo faktura, soliqservis uz faktura, online faktura uz, my soliq, my soliq uz, soliq servis, soliq uz электронная, hisobot soliq uz, may soliq, may soliq uz, tasnif soliq uz, фактура, счет фактура, фактура уз, солик фактура, фактура узбекистана, счет фактура узбекистан, е фактура, фактура солик уз, электронный фактура, электронные счета фактуры, солик сервис уз фактура, сервис фактура, выставить фактуру",
        },
      ],
    };
  },
  created() {
    this.spinerInProgerss = true
  },
};
</script>
  
<style>
.range-input-container {
  margin: 25px 0;
  position: relative;
  width: 80%;
  font-size: 12px;
}

.mobile-show {
  display: none;
}

.range-input-container .document-range-amount {
  font-size: 12px;
}

.price-backgroud {
  background: #f7fff2;
  border-radius: 35px;
  padding: 0px 25px;
  box-shadow: 0 7px 15px hsla(0, 0%, 57%, .319) !important;
}

.input-custom-range {
  outline: 0px !important;
  border: none;
  width: 5%;
  background-color: #f7fff2;
  padding: 0px 5px;
  border-radius: 10px;
}

.input-custom-range::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.range-input-container .document-range-amount span {
  font-size: 16px;
  font-weight: 600;
}

.range-input-container .document-amount-info-container {
  width: 97.3%;
}

.range-value {
  margin: 0;
  position: relative;
  color: #fff;
  width: 35px;
  height: 35px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 3px;
  transform: translateX(-55%);
  line-height: 55px;
  z-index: 2;
  left: 0%;
}

.range-value::after {
  position: absolute;
  content: "";
  background-color: #72BD49;
  width: 40px;
  height: 40px;
  transform: translateX(-45%) rotate(45deg);
  z-index: -1;
  left: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 55%;
  border-bottom-left-radius: 55%;

}


input[type="range"] {
  font-size: 1.5rem;
  width: 100%;

}

input[type="range"] {
  color: #72BD49;
  --thumb-height: 0.9em;
  --track-height: 4px;
  --track-color: rgba(0, 0, 0, 0.2);
  --clip-edges: 0.125em;

}



/* === range commons === */
input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;

}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further));

}



input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);

}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }

  .range-input-container .document-amount-info-container {
    width: 94%;
  }

  .input-custom-range {
    width: 35% !important;
  }

  .mobile-show {
    display: block;
  }
}

@media (max-width: 576px) {
  .range-input-container .document-amount-info-container {
    width: 92%;

  }

}
</style>
  