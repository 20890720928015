<template>
    <div class="vertical-align-center mt-5">
        <h1 class="title-font">{{ possibility.title }}</h1>
        
        <div class="btn-container mb-5" v-if="possibility.tabs.length > 1"> 
            <button  class="dropbtn article-text article-text-bold" v-for="(tab, i) in possibility.tabs" :key="i" :id="index + '.'+i" :class="(tabindex==i) ? 'article-text  default-btn' : ' mx-3'" @click="()=>{tabindex = i}"> {{ tab.btn }}</button>
        </div>
        <p class="mt-1 description-font text-center"> {{ possibility.tabs[tabindex].content }}</p>
        <div style="display:flex; justify-content: center;">
            <img  :style="'width:'+ possibility.tabs[tabindex].width" style="transition: 0.5s;" :src="require(`../assets/images/possibilities/${possibility.tabs[tabindex].img}`)" />
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        possibility: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            tabindex: 0,
        }
    }

}
</script>
<style>
.btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.article-wrapper {
    width: 90%;
}
.vertical-align-center{
  position: relative;
}

.article-text{
    font-size: 18px !important;
}
.dropbtn {
    transition: 0.2s;
}
.default-btn {
    padding: 10px 16px !important;
    margin: 15px 0 !important;
    transition: 0.2s;
}
</style>