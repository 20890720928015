<template>
  <div id="mainheader">
    <header id="header" :class="{ 'headroom--unpinned': scrolled, 'headroom': isScrolled, 'isTopHeader': isTopHeader }">
      <div class="box-call-center">
        <div class="container-fluid">
          <div class="call-center d-flex justify-content-between">
            <div class="item-call">
              <img src="../assets/images/tel-phone-icon.png" alt="icon">
              <span>Колл-центр:</span>
            </div>
            <div class="item-call">
              <a href="tel:712000013" class="a-dec-none">+998 71-200-00-13</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-navbar">
        <div class="container">
          <div class="nav-block">
            <div class="logo-navbar d-flex justify-content-between align-items-center">
              <svg @click="showMobileMenu" class="bi-list-icon" width="24px" height="24px" viewBox="0 0 20 20">
                <path fill="#4E7BF9"
                  d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z" />
              </svg>
              <div class="dropdown">
                <button class="btn-vector" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                  aria-expanded="false">
                  <img src="../assets/images/LogoServises/dots-menu.png" alt="Vector" class="Vector">
                </button>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <div class="row">
                    <div class="col-lg-6">
                      <a href="https://edo.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/edo.png" alt="png" class="vector-in-img"
                          style="height: 38px; width: auto">
                        <p class="InVector-description">Сервис для обмена электронными
                          документами</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://report.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/report-logo.png" alt="" class="vector-in-img"
                          style="height: 38px; width: auto">
                        <p class="InVector-description">Генерация готовых отчетов за
                          указанный период</p>
                      </a>
                    </div>

                    <div class="col-lg-6">
                      <a href="https://botcrm.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/crm.png" alt="png" class="vector-in-img"
                          style="height: 30px; width: auto">
                        <p class="InVector-description mt-1">Конструктор чат-ботов для
                          Telegram</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://push.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src="../assets/images/push.png" alt="png" class="vector-in-img"
                          style="height: 32px; width: auto">
                        <p class="InVector-description">Рассылка рекламных материалов по
                          базе ИНН</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://org.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src="../assets/images/ORG.png" alt="png" class="vector-in-img"
                          style="height: 30px; width: auto">
                        <p class="InVector-description">Сервис для проверки контрагентов</p>
                      </a>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-lg-12 mt-4 mb-4">

                      <a class="home-redirect-btn" target="_blank" href="https://faktura.uz/"> Все продукты от Faktura.uz
                        <svg width="11" height="8" viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464466C6.97631 0.269204 6.65973 0.269204 6.46447 0.464466C6.2692 0.659728 6.2692 0.976311 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM0 4.5L10 4.5V3.5L0 3.5L0 4.5Z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
              <router-link :to="{ name: 'home' }"><img src="../assets/images/LogoServises/logo.svg" alt="logo"
                  style="width:210px; height:auto;"></router-link>
            </div>

            <div class="navbar-box d-flex align-items-center justify-content-between">
              <ul class="box-menu">
                <router-link :to="{ name: 'home' }" @click="redirectUrl('aboutService')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">О сервисе</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('rasilka')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''"> Возможности</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('audience')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">Шаблоны</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('price')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">Цена</li>
                </router-link>
              </ul>

              <form
                action="https://account.faktura.uz/Login?ReturnUrl=http%3a%2f%2faccount.faktura.uz%2fAuthorize%3fresponse_type%3dcode%26client_id%3d12379127389123%26redirect_uri%3dhttps%253a%252f%252fapp.faktura.uz%252faccount%252fexternallogin%26state%3d%252f%26scope%3d0%252c1%252c2%252c3"
                target="_blank">
                <input class="btn-sign-in header_sign_in ml-20" type="submit" value="Войти">
              </form>
            </div>
          </div>

          <transition name="slide-fade">
            <div v-show="mobileMenu" v-cloak class="navbar-collapse">
              <ul class="navbarMobile">
                <router-link :to="{ name: 'home' }" @click="redirectUrl('aboutService')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">О сервисе</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('rasilka')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''"> Возможности</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('audience')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">Шаблоны</li>
                </router-link>
                <router-link :to="{ name: 'home' }" @click="redirectUrl('price')" class="a-dec-none">
                  <li class="nav-item text-gray font-weight-normal"
                    :class="(this.$route.name === 'price') ? ' active' : ''">Цена</li>
                </router-link>
                <div class="TheLine"></div>
              </ul>
            </div>
          </transition>

        </div>
      </div>
    </header>
  </div>
</template>

<script defer>
export default {
  name: 'mainheader',
  data() {
    return {
      mobileMenu: false,
      isScrolled: false,
      scrolled: false,
      scrollPosition: null,
      limitPosition: 500,
      lastPosition: 0,
      countForSlider: 0
    }
  },
  methods: {
    redirectUrl(hashtag) {
      console.log(this.$route.name);
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home' });
      }
      else {

        this.mobileMenu = false;
        window.scrollTo(0, 0);
        let position = document.getElementById(hashtag).getBoundingClientRect()
        window.scrollTo({
          top: position.y - 80,
          behavior: 'smooth'
        })
      }
    },
    showMobileMenu() {
      this.mobileMenu = !this.mobileMenu
    },
    updateScroll() {
      /* eslint-disable no-debugger */
      this.scrollPosition = window.scrollY

      if (this.scrollPosition > 10) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }

      if (this.scrollPosition < 20) {
        this.isTopHeader = true;
      } else {
        this.isTopHeader = false;
      }
    },

    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }

      this.lastPosition = window.scrollY;
      // this.scrolled = window.scrollY > 250;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    setInterval(() => {
      if (500 < this.count) {
        document.querySelector("html body div#app section div.block-partners div.container.slider-ct div.row.slide-wr").style.transform = `translateX(${this.count++}%)`
      }
    }, 40)
  },
  created() {
    if (window.scrollY == 0) {
      this.isTopHeader = true;
    } else {
      this.isTopHeader = false;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}

</script>

<style>
a {
  text-decoration: none !important;
}
</style>
