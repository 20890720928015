<template>
    <div>
        <mainheader></mainheader>
        <section>
            <div class="container">
                <div class="article-container">
                    <div class="article-wrapper">
                        <h2 class="title-font">Спасибо за вашу заявку</h2>
                        <br>
                        <p class="description-font">Наши операторы свяжутся с вами в ближайшее время</p>
                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn " @click="() => {this.$router.push({name:'home'})}" >Главная страница</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <mainfooter></mainfooter>
    </div>
</template>


<script>
import mainheader from "./mainheader.vue";
import mainfooter from "./mainfooter.vue";

export default {
    name: 'thanks',
    components: {
        mainheader,
        mainfooter
    },
  metaInfo() {
    return {
      title: "Спасибо за вашу заявку",
      meta: [
        {
          name: "description",
          content:
            "",
        },
      ],
    };
  },
}
</script>

<style scoped> 
.article-container {
    margin-top: 25px;
    text-align: center;
}
.article-questions-btn-container {
    margin-top: 45px;
}
</style>